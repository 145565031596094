












































.align-item {
  display: inline-block;
  border: 1px solid transparent;
  padding: 3px 5px 0px 5px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 6px;
  i {
    color: rgba(0, 27, 72, 0.54);
    font-size: 20px;
  }
  &.active {
    border-color: rgba(0, 27, 72, 0.12);
    color: rgba(0, 14, 36, 0.87);
  }
}
