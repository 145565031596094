
















































.form-group {
  position: relative;
  .unit {
    position: absolute;
    right: 8px;
    top: 36px;
  }
  input[type='number'] {
    padding-right: 25px;
  }
}
