




































































.color-group {
  position: relative;
  .form-control {
    padding-left: 34px;
  }
  .color-preview {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 40px;
    left: 10px;
    cursor: pointer;
  }
}
