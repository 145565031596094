
























































































































































































































.input-container {
  display: flex;
  .form-control {
    flex: 1 1 auto;
  }
}
.btn-upload {
  background: transparent;
  border: 1px solid #e2e7f1;
  margin-left: 5px;
  border-radius: 5px;
  padding: 0px 11px;
  color: #414141;
  cursor: pointer;
  &.btn-remove {
    color: #ec0303;
    border-color: #ec0303;
  }
}

.splash-images {
  position: absolute;
  max-width: 100%;
  width: 370px;
  background: #fff;
  border: 1px solid #e2e7f1;
  border-radius: 3px;
  box-shadow: 0px 0px 4px #e2e7f1;
  z-index: 50;
  padding: 10px;
  right: 12px;
  top: 80px;
  img {
    cursor: pointer;
    width: 100px;
    margin: 5px;
    border-radius: 3px;
    &:hover {
      opacity: 0.5;
    }
  }
  &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e2e7f1;
    top: -10px;
    right: 58px;
  }
  &:after {
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 9px solid #fff;
    top: -9px;
    right: 60px;
  }

  .list-images {
    max-height: 340px;
    overflow-y: scroll;
  }
}
